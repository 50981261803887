<!-- 人才管理 -->
<template>
  <div class="talents_manage">

    <div class="empty" v-if="!or_empty">
      <img src="@/assets/images/noRencai.png" alt="">
      <p>您还未有心仪的人才哦！</p>
      <Button type="warning" @click="go_talents_list">前去寻找</Button>
    </div>

    <div class="list" v-else>

      <header class="inp">
        <Button type="warning" size="large" @click="search">搜索</Button>
        <Input v-model="obj.name" @on-enter="search" size="large" prefix="ios-search" placeholder="输入关键词，搜索相关人才">
        </Input>
      </header>

      <article>
        <div class="tabs">
          <Button :type="obj.status?'text':'warning'" size="small" @click="get_list('')">全部</Button>
          <Button style="margin-left:20px" :type="obj.status==150?'warning':'text'" size="small" @click="get_list(150)">收藏的人才</Button>
        </div>
      </article>

      <section>
        <div class="list">
          <Spin size="large" fix v-if="spinShow"></Spin>
          <ul>
            <li v-for="(item,index) in talented_list" :key="item._id">

              <Row>
                <Col span="2" style="text-align:center">
                <!-- <img :src="require(`@/assets/images/${Icon(item.sex)}`)" alt=""> -->
                <img :src="item.icon" alt="">
                </Col>
                <Col span="20">
                <!-- <p style="color:#000;">{{item.name[0]}}{{item.sex == 1?'先生':'小姐'}}</p> -->
                <p style="color:#000;">{{item.name}}</p>
                <p style="margin:15px 0;color:#000;">
                  <span>{{jsGetAge(item.birthDate)}}岁丨</span>
                  <span>{{working_age(item.workedDate)}}年工作经验丨</span>
                  <span>{{item.education}}</span>
                  <span style="margin-left:20px">
                    <Icon type="ios-call" color="#f90" size="20" />
                  </span>
                  <span>{{item.phone}}</span>
                </p>
                <p>
                  <span>
                    期望：
                    {{item.fullTime?item.fullTime.trade[0]+'|':''}}
                    {{item.fullTime?item.fullTime.jobIntention[0]+'|':''}}
                    {{item.fullTime?item.fullTime.workplace[0]+'-':''}}
                    {{item.fullTime?item.fullTime.workplace[1]+'-':''}}
                    {{item.fullTime?item.fullTime.workplace[2]+'|':''}}
                    {{item.fullTime?item.fullTime.pay:''}}
                  </span>
                </p>
                </Col>
                <Col span="2">
                <Button type="warning" long @click="$router.push({name:'talents_details',query:{id:item.userId}})">前去查看</Button>
                </Col>
              </Row>

            </li>
          </ul>
        </div>
      </section>

      <footer v-show="this.talented_list.length > 9">
        <Button type="warning" @click="next">下一页</Button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      or_empty: true,
      spinShow: false,
      obj: {
        status: '',
        name: "",
        isCount: false,
        page: 1,
        size: 10
      },
      talented_list: []
    }
  },
  computed: {
    working_age() {
      return (data) => {
        var workedDate = data[0] + data[1] + data[2] + data[3];
        var y = new Date().getFullYear()
        return y - workedDate
      }
    }
  },
  methods: {
    //搜索
    search() {
      this.get_data()
    },
    //跳转寻找人才
    go_talents_list() {
      this.$router.push({ name: 'talents_list' })
      this.$emit("setTitle", "人才列表")
    },
    //下一页
    next() {
      this.obj.page++
      this.get_data()
      document.getElementsByClassName("ivu-layout")[1].scrollTop = 0

    },
    //切换全部or收藏
    get_list(val) {
      this.obj.status = val
      this.obj.page = 1
      this.get_data()
    },
    //封装出生年月转换年龄
    jsGetAge(strBirthday) {
      var returnAge;
      var strBirthdayArr = strBirthday.split("-");

      var birthYear = strBirthdayArr[0];
      var birthMonth = strBirthdayArr[1];
      var birthDay = strBirthdayArr[2];

      var d = new Date();
      var nowYear = d.getFullYear();
      var nowMonth = d.getMonth() + 1;
      var nowDay = d.getDate();
      if (nowYear == birthYear) {
        returnAge = 0;//同年 则为0岁
      }
      else {
        var ageDiff = nowYear - birthYear; //年之差

        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay;//日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1;
            }
            else {
              returnAge = ageDiff;
            }
          }
          else {
            var monthDiff = nowMonth - birthMonth;//月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1;
            }
            else {
              returnAge = ageDiff;
            }
          }
        }
        else {
          returnAge = '';//返回-1 表示出生日期输入错误 晚于今天
        }
      }
      return returnAge;//返回周岁年龄
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.obj.enterpriseId = localStorage.getItem('firm_enterpriseId')
      this.app('enterpriseOperators', 'talentedPeopleList', this.obj).then(res => {
        console.log(res);
        this.talented_list = res.data

        this.talented_list.forEach((item, index) => {
          //头像存在转换在线头像图片
          if (item.personalImage) {
            item.personalImage = item.personalImage.replace('cloud://hphronline-0gf0t91ufecc02db.6870-hphronline-0gf0t91ufecc02db-1303180979', 'https://6870-hphronline-0gf0t91ufecc02db-1303180979.tcb.qcloud.la');
          } else {
            //否则生成随机头像
            if (item.sex == 1) {
              let num = Math.ceil(Math.random() * 1000000000 % 9);
              if (num == 1 || num == 2 || num == 3) {
                item.icon = require(`@/assets/images/man_1.png`)
              } else if (num == 4 || num == 5 || num == 6) {
                item.icon = require(`@/assets/images/man_2.png`)
              } else {
                item.icon = require(`@/assets/images/man_3.png`)
              }
            } else {
              let num = Math.ceil(Math.random() * 1000000000 % 9);
              if (num == 1 || num == 2 || num == 3) {
                item.icon = require(`@/assets/images/women_1.png`)
              } else if (num == 4 || num == 5 || num == 6) {
                item.icon = require(`@/assets/images/women_2.png`)
              } else {
                item.icon = require(`@/assets/images/women_3.png`)
              }
            }
          }
        })
        if (!this.obj.status) {
          this.or_empty = this.talented_list.length > 0
        } else if (this.talented_list.length < 1) {
          this.$Modal.warning({
            title: '暂无收藏的人才',
            closable: true
          })
        }


        this.spinShow = false
      })
    },
  },
  activated() {
    this.get_data()
  },
  created() {
    this.get_data()
  }
}
</script>

<style lang="less" scoped>
@import "./talents_manage.less";
</style>
